import Head from 'next/head';
import type { ComponentProps, CSSProperties, FC } from 'react';
import Footer from '@components/structure/Footer';
import Logo from '@components/ui/branding/Logo/Logo';
import Box from '@mui/material/Box';
import makeTitle from '@utils/makeTitle';

export interface CenteredLayout extends ComponentProps<'main'> {
  /** The page title (equivalent to the <title> tag in the page <head>). */
  title: string;
  /** The page description (equivalent to the <meta name="description"> tag in the page <head>). */
  description?: string;
  width?: CSSProperties['width'];
  logo?: boolean | number;
}

const CenteredLayout: FC<CenteredLayout> = ({ title, description, width, logo = false, ...props }) => (
  <>
    <Head>
      <title>{makeTitle(title)}</title>
      {description && <meta name="description" content={description} />}
    </Head>

    <div className="flex flex-col min-h-[100vh]">
      <div className="w-full container py-6 flex items-center justify-center flex-grow">
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center' }}>
          {logo && <Logo width={typeof logo === 'number' ? logo : 240} />}

          <main style={{ width }} {...props} />
        </Box>
      </div>

      <Footer />
    </div>
  </>
);

export default CenteredLayout;
