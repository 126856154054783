import classnames from 'classnames';
import { Trans } from 'next-i18next';
import Image from 'next/image';
import { BsMedium, BsTwitter } from 'react-icons/bs';
import { IoNewspaperSharp } from 'react-icons/io5';
import type { ComponentProps, VFC } from 'react';
import Link from '@components/routing/Link';
import PoweredByAvalanche from '@components/web3/PoweredByAvalanche';
import discord from '@public/icons/social/discord.svg';
import instagram from '@public/icons/social/instagram.svg';
import reddit from '@public/icons/social/reddit.svg';
import telegram from '@public/icons/social/telegram.svg';
import youtube from '@public/icons/social/youtube.svg';

export interface FooterProps extends Omit<ComponentProps<'footer'>, 'children'> {}

const Footer: VFC<FooterProps> = ({ className, ...props }) => {
  return (
    <footer
      className={classnames(
        className,
        'flex flex-col p-3 bg-black bg-opacity-40 text-center text-sm items-center justify-center space-y-4 lg:space-y-0 lg:space-x-20 sm:flex-row',
      )}
      {...props}
    >
      <PoweredByAvalanche height={20} />

      <span className="block align-baseline size">
        <Link href="https://deepsquare.io" className="text-white decoration-white">
          The DeepSquare Association
        </Link>{' '}
        &copy; 2021 - {new Date().getFullYear()}, Switzerland -{' '}
        <Link className="text-white decoration-white" href="https://deepsquare.io/privacy-policy/">
          <Trans i18nKey="privacy-policy">Privacy Policy</Trans>
        </Link>
      </span>
      <div className="flex gap-4 items-center justify-center text-lg">
        <Link className="flex" raw href="https://discord.gg/KYWh28BkUE">
          <Image src={discord} width={18} height={18} layout="fixed" alt="Discord logo" />
        </Link>
        <Link className="flex" raw href="https://t.me/DeepSquareProject">
          <Image src={telegram} width={18} height={18} layout="fixed" alt="Telegram logo" />
        </Link>
        <Link className="flex" raw href="https://medium.com/@DeepSquare.io">
          <BsMedium color="white" />
        </Link>
        <Link className="flex" raw href="https://twitter.com/DeepSQUAREio">
          <BsTwitter color="#1DA1F2" />
        </Link>
        <Link className="flex" raw href="https://www.youtube.com/channel/UC05eS4t5M0Iq6xb0NP3SxGg">
          <Image src={youtube} width={18} height={18} layout="fixed" alt="YouTube logo" />
        </Link>
        <Link className="flex" raw href="https://www.reddit.com/r/DeepSquare/">
          <Image src={reddit} width={18} height={18} layout="fixed" alt="Reddit logo" />
        </Link>
        <Link className="flex" raw href="https://deepsquare.gitbook.io/white-paper/nbKUltb7gLjwAhl9vMcw/">
          <IoNewspaperSharp color="white" />
        </Link>
        <Link className="flex" raw href="https://www.instagram.com/deepsquare.io/">
          <Image src={instagram} width={18} height={18} layout="fixed" alt="Reddit logo" />
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
