import type { ImageProps } from 'next/image';
import Image from 'next/image';
import { useMemo } from 'react';
import type { VFC } from 'react';
import logo192 from '@public/img/deepsquare-logo-x192.png';
import logo240 from '@public/img/deepsquare-logo-x240.png';
import logo from '@public/img/deepsquare-logo.png';

type LogoProps = Omit<ImageProps, 'src' | 'alt'>;

/**
 * DeepSquare logo.
 */
const Logo: VFC<LogoProps> = ({ width: _width, ...props }) => {
  const width = useMemo(() => {
    switch (typeof _width) {
      case 'string':
        return parseFloat(_width);
      case 'number':
        return _width;
      default:
        return undefined;
    }
  }, [_width]);

  const img = useMemo(() => {
    if (!width) {
      return logo;
    } else if (0 < width && width <= 192) {
      return logo192;
    } else if (192 < width && width <= 240) {
      return logo240;
    } else {
      return logo;
    }
  }, [width]);

  const height = useMemo(() => {
    return width ? (width * img.height) / img.width : undefined;
  }, [img.height, img.width, width]);

  return <Image src={img} alt="DeepSquare Logo" {...props} width={width} height={height} />;
};

export default Logo;
