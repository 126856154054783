const defaultOptions = {
  prefix: 'DeepSquare',
  suffix: 'Smart, Sustainable, and Democratized High Performance Computing Ecosystem',
  separator: '—',
};

/**
 * Generate SEO-friendly titles using the constants above.
 * @param {string} parts
 * @returns {string}
 */
export default function makeTitle(...parts: string[]): string {
  if (parts.length === 0) {
    parts.push(defaultOptions.prefix, defaultOptions.suffix);
  }

  if (parts[0] !== defaultOptions.prefix) {
    parts.unshift(defaultOptions.prefix);
  }

  return parts.join(` ${defaultOptions.separator} `);
}
