/**
 * The supported chain ids.
 */
enum ChainId {
  AVALANCHE_MAINNET = '0xa86a', // 43114
  AVALANCHE_TESTNET = '0xa869', // 43113
  AVALANCHE_LOCALNET = '0x539', // 1337
}

export default ChainId;
