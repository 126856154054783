import classnames from 'classnames';
import type { LinkProps as NextLinkProps } from 'next/link';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import type { UrlObject } from 'url';
import type { AnchorHTMLAttributes } from 'react';
import { forwardRef } from 'react';
import NativeLink from '@components/routing/NativeLink';

export function useRoutedComponentClassnames(
  href: string | UrlObject,
  activeClassName: string,
  classNameProps?: string,
) {
  const router = useRouter();
  const pathname = typeof href === 'string' ? href : href.pathname;
  return classnames(classNameProps, {
    [activeClassName]: router.pathname === pathname && activeClassName,
  });
}

export interface NextLinkComposedProps
  extends Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>,
    Omit<NextLinkProps, 'href' | 'as'> {
  to: NextLinkProps['href'];
  linkAs?: NextLinkProps['as'];
}

export const NextLinkComposed = forwardRef<HTMLAnchorElement, NextLinkComposedProps>(function NextLinkComposed(
  props,
  ref,
) {
  const { to, linkAs, replace, scroll, shallow, prefetch, locale, ...other } = props;

  return (
    <NextLink
      href={to}
      prefetch={prefetch}
      as={linkAs}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      passHref
      locale={locale}
    >
      <NativeLink ref={ref} {...other} />
    </NextLink>
  );
});
