import merge from 'lodash/merge';
import type { ZodString } from 'zod';
import { z } from 'zod';
import ChainId from '@lib/web3/lib/ChainId';
import { isPlatformBrowser, isPlatformServer } from '@utils/platform';

const origin = isPlatformBrowser() ? window.origin : undefined;
const rawAppUrl = origin ?? process.env.NEXT_PUBLIC_APP_URL ?? process.env.NEXT_PUBLIC_VERCEL_URL;
const APP_URL = rawAppUrl ? rawAppUrl.replace(/\/*$/, '') : '/';

/**
 * The raw environment, based on the original process.env and with the addition of the NEXT_PUBLIC variables.
 */
const raw = merge(isPlatformServer() ? process.env : {}, {
  // Application
  NEXT_PUBLIC_APP_ENV: process.env.NEXT_PUBLIC_APP_ENV ?? 'production',
  NEXT_PUBLIC_APP_URL: APP_URL,
  NEXT_PUBLIC_API_URL: process.env.NEXT_PUBLIC_API_URL ?? `${APP_URL}/api`,
  NEXT_PUBLIC_WEBHOOKS_URL: process.env.NEXT_PUBLIC_WEBHOOKS_URL ?? `${APP_URL}/api/webhooks`,
  NEXT_PUBLIC_MAINTENANCE: process.env.NEXT_PUBLIC_MAINTENANCE ?? '0',

  // Web3
  NEXT_PUBLIC_WEB3_CHAIN: process.env.NEXT_PUBLIC_WEB3_CHAIN ?? ChainId.AVALANCHE_MAINNET,

  // Third parties
  NEXT_PUBLIC_STRIPE_PUBLIC_KEY: process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY,
  NEXT_PUBLIC_GTM_ID: process.env.NEXT_PUBLIC_GTM_ID,
  NEXT_PUBLIC_HUBSPOT_TRACKING_ID: process.env.NEXT_PUBLIC_HUBSPOT_TRACKING_ID,
  NEXT_PUBLIC_VERIFF_API_KEY: process.env.NEXT_PUBLIC_VERIFF_API_KEY,
});

type PublicVariables = Extract<keyof typeof raw, `NEXT_PUBLIC_${string}`>;
type PublicSchema = Record<PublicVariables, ZodString>;

const publicSchema = z.lazy(() => {
  const keys: PublicVariables[] = Object.keys(raw).filter<PublicVariables>(
    (k: string): k is PublicVariables => k.startsWith('NEXT_PUBLIC_') && k !== 'NEXT_PUBLIC_VERCEL_GIT_PREVIOUS_SHA',
  );

  return z.object(
    keys.reduce((schema, key) => {
      if (!(key in schema)) {
        schema[key] = z.string().nonempty();
      }

      return schema;
    }, {} as PublicSchema),
  );
});

const privateSchema = z.intersection(
  publicSchema,
  z.object({
    // ECDSA keypair for user authentication
    JWT_PRIVATE_KEY: z.string().nonempty(),
    JWT_PUBLIC_KEY: z.string().nonempty(),

    // Web3
    WEB3_PRIVATE_KEY: z.string().regex(/(?:0x)?[0-9a-fA-F]{64}/),

    // Third parties
    MONGODB_URI: z.string().nonempty(),
    REDIS_URI: z.string().nonempty(),

    SMTP_URI: z.string().nonempty(),
    SMTP_FROM: z.string().optional(),

    STRIPE_SECRET_KEY: z.string().nonempty(),
    HELLOSIGN_API_KEY: z.string().nonempty(),
    HUBSPOT_ACCESS_TOKEN: z.string().nonempty(),

    VERIFF_PRIVATE_KEY: z.string().nonempty(),
  }),
);

const schema = isPlatformBrowser() ? publicSchema : privateSchema;
const env = schema.parse(raw) as z.infer<typeof privateSchema>;

export default env;
