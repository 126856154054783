import classnames from 'classnames';
import { Trans } from 'next-i18next';
import Image from 'next/image';
import type { VFC } from 'react';
import avalanche from '@public/img/avalanche-logo.svg';

interface PoweredByAvalancheProps {
  inline?: boolean;
  height?: number;
  className?: string;
}

const PoweredByAvalanche: VFC<PoweredByAvalancheProps> = ({ inline = true, height = 30, className }) => {
  const width = (height * avalanche.width) / avalanche.height;

  return (
    <div className={classnames('flex items-center gap-2', inline || 'flex-col', className)}>
      <span>
        <Trans i18nKey="powered-by">Powered by</Trans>
      </span>{' '}
      <Image src={avalanche} alt="Avalanche Logo" width={width} height={height} layout="fixed" />
    </div>
  );
};
export default PoweredByAvalanche;
