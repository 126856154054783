/* eslint-disable @typescript-eslint/unbound-method */
/**
 * Workaround the Google Translate issue savagery.
 * @link https://github.com/facebook/react/issues/11538#issuecomment-417504600
 */
if (typeof Node === 'function' && Node.prototype) {
  const originalRemoveChild = Node.prototype.removeChild;
  Node.prototype.removeChild = function <T extends Node>(child: T) {
    if (child.parentNode !== this) {
      if (console) {
        console.warn('Cannot remove a child from a different parent', { node: this, child });
      }

      return child;
    }

    return originalRemoveChild.apply(this, [child]) as T;
  };

  const originalInsertBefore = Node.prototype.insertBefore;
  Node.prototype.insertBefore = function <T extends Node>(newNode: T, referenceNode: Node | null) {
    if (referenceNode && referenceNode.parentNode !== this) {
      if (console) {
        console.warn('Cannot insert before a reference node from a different parent', {
          node: this,
          reference: referenceNode,
        });
      }

      return newNode;
    }

    return originalInsertBefore.apply(this, [newNode, referenceNode]) as T;
  };
}

export {};
