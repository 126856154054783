import type { LinkProps as NextLinkProps } from 'next/link';
import { BiLinkExternal } from 'react-icons/bi';
import type { FC } from 'react';
import NativeLink from '@components/routing/NativeLink';
import type { NextLinkComposedProps } from '@components/routing/NextLinkComposed';
import { NextLinkComposed, useRoutedComponentClassnames } from '@components/routing/NextLinkComposed';
import type { LinkProps as MuiLinkProps } from '@mui/material/Link';
import MuiLink from '@mui/material/Link';

export type LinkProps = {
  activeClassName?: string;
  as?: NextLinkProps['as'];
  href: NextLinkProps['href'];
  linkAs?: NextLinkProps['as']; // Useful when the as prop is shallow by styled().
  raw?: boolean;
} & Omit<NextLinkComposedProps, 'to' | 'linkAs' | 'href'> &
  Omit<MuiLinkProps, 'href'>;

// A styled version of the Next.js Link component:
// https://nextjs.org/docs/api-reference/next/link
const Link: FC<LinkProps> = ({
  activeClassName = 'active',
  as,
  children,
  className: classNameProps,
  href,
  linkAs: linkAsProp,
  locale,
  prefetch,
  raw,
  replace,
  role, // Link don't have roles.
  scroll,
  shallow,
  ...other
}) => {
  const className = useRoutedComponentClassnames(href, activeClassName, classNameProps);

  const isExternal = typeof href === 'string' && (href.indexOf('http') === 0 || href.indexOf('mailto:') === 0);

  if (isExternal) {
    if (raw) {
      return (
        <NativeLink className={className} href={href} target="_blank" rel="noreferrer" {...other}>
          {children}
        </NativeLink>
      );
    }

    return (
      <MuiLink className={className} href={href} target="_blank" rel="noreferrer" {...other}>
        {children}
        <BiLinkExternal />
      </MuiLink>
    );
  }

  const linkAs = linkAsProp || as;
  const nextjsProps = { to: href, linkAs, replace, scroll, shallow, prefetch, locale, children };

  if (raw) {
    return <NextLinkComposed className={className} {...nextjsProps} {...other} />;
  }

  return <MuiLink component={NextLinkComposed} className={className} {...nextjsProps} {...other} />;
};

export default Link;
