import type { GetStaticProps } from 'next';
import type { NextPage } from 'next';
import { Trans, useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import CenteredLayout from '@components/layouts/CenteredLayout';

const MaintenancePage: NextPage = () => {
  const { t } = useTranslation();

  return (
    <CenteredLayout title={t('maintenance-title', { defaultValue: 'Under maintenance' })} logo>
      <p>
        <Trans i18nKey="website-under-maintenance">The website is currently under maintenance.</Trans>
      </p>
    </CenteredLayout>
  );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => ({
  props: await serverSideTranslations(locale ?? 'en'),
});

export default MaintenancePage;
