import { appWithTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import Script from 'next/script';
import isEnabled, { Feature } from '@lib/app/maintenance';
import '@lib/polyfills/translate-workaround';
import MaintenancePage from '@pages/maintenance';
import '@styles/globals.css';

const Runtime = dynamic(import('@components/Runtime'));

function DeepSquare({ Component, pageProps }: AppProps) {
  const isMaintenance = !isEnabled(Feature.GLOBAL);

  return (
    <>
      <Head>
        <meta name="release" content={process.env.NEXT_PUBLIC_APP_RELEASE} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>

      <Runtime>{isMaintenance ? <MaintenancePage /> : <Component {...pageProps} />}</Runtime>

      {/* HubSpot chat widget */}
      <Script
        id="hs-script-loader"
        src={`//js-eu1.hs-scripts.com/${process.env.NEXT_PUBLIC_HUBSPOT_TRACKING_ID}.js`}
        strategy="lazyOnload"
      />

      <Script type="text/javascript" src="//js-eu1.hsforms.net/forms/embed/v2.js" />
    </>
  );
}

export default appWithTranslation(DeepSquare);
