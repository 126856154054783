export type Platform = 'server' | 'browser' | 'cypress';

/**
 * @returns {boolean} If we are on a server (serverless function or SSR).
 */
export function isPlatformServer(): boolean {
  return typeof window === 'undefined';
}

/**
 * @returns {boolean} If we are in a browser (`window` and `document` are accessible).
 */
export function isPlatformBrowser(): boolean {
  return typeof window !== 'undefined';
}

/**
 * @returns {boolean} If we are in a browser running Cypress tests.
 */
export function isPlatformCypress(): boolean {
  return isPlatformBrowser() && typeof window.Cypress !== 'undefined';
}

/**
 * @returns {Platform} The current running platform.
 */
export default function platform(): Platform {
  if (isPlatformServer()) {
    return 'server';
  } else if (isPlatformCypress()) {
    return 'cypress';
  }

  return 'browser';
}
