import env from './env';

export enum Feature {
  GLOBAL, // 1
  CRYPTO_PAYMENT, // 2
  CARD_PAYMENT, // 4
  WIRE_PAYMENT, // 8
  VESTING_PAGE,
}

let maintenance = 0;

if (env.NEXT_PUBLIC_MAINTENANCE) {
  if (env.NEXT_PUBLIC_MAINTENANCE.startsWith('0b')) {
    maintenance = parseInt(env.NEXT_PUBLIC_MAINTENANCE.replace(/^0b/, ''), 2);
  } else if (env.NEXT_PUBLIC_MAINTENANCE.startsWith('0x')) {
    maintenance = parseInt(env.NEXT_PUBLIC_MAINTENANCE.replace(/^0x/, ''), 16);
  } else {
    maintenance = parseInt(env.NEXT_PUBLIC_MAINTENANCE, 10);
  }
}

export default function isEnabled(feature: Feature): boolean {
  return !((maintenance >> feature) & 1);
}
